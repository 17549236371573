import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../UI/Input';
import Button from '../UI/Button';
import classes from './Unpack.module.css';

export const Unpack = ({ save, close }) => {
  const [codeOut, setcodeOut] = useState('');
  const [codeIn, setcodeIn] = useState('');
  const [codesIn, setcodesIn] = useState([]);
  const addCode = code => {
    setcodesIn([...codesIn, code]);
  };
  const datamatrixView = code => {
    if (code.length === 20) {
      return code;
    }
    if (`${code[0]}${code[1]}` === '01') {
      return `${code.slice(2, 16)}  ${code.slice(18, 31)}`;
    }
    return `${code.slice(3, 17)}  ${code.slice(19, 32)}`;
  };
  const dataOutView = (
    <div className={classes.codesOut}>{codeOut}</div>
  );
  const dataInView = (
    <div className={classes.codes}>
      {codesIn.map(el => {
        return (
          <div className={classes.code} key={el}>
            {datamatrixView(el)}
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={classes.wrapper}>
      <div className={classes.buttons}>
        <Button
          btnType="editButton"
          onClick={() => save({ out: codeOut, in: codesIn })}
        >
          Занести данные
        </Button>
        <div></div>
        <Button btnType="closeButton" onClick={close}>
          X
        </Button>
      </div>
      <div className={classes.codeOut}>
        <Input
          name="codeOut"
          label="Код агрегации"
          value={codeOut}
          onChange={e => setcodeOut(e.target.value)}
        />
      </div>
      {dataOutView}
      <div className={classes.codeIn}>
        <Input
          name="codeIn"
          label="Код меньшей упаковки"
          value={codeIn}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              addCode(codeIn);
              setcodeIn('');
            }
          }}
          onChange={e => {
            setcodeIn(e.target.value);
          }}
        />
      </div>
      {dataInView}
    </div>
  );
};

Unpack.propTypes = {
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default Unpack;
