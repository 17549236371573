import * as Constants from '../constants';

const initialState = {
  token: localStorage.getItem('token'),
  isLoading: false,
  authError: '',
  sending: true,
  sended: false,
  userId: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.LOGIN: {
      localStorage.setItem('token', action.payload.data.token);
      state = {
        ...state,
        isLoading: false,
        token: action.payload.data.token,
        authError: '',
        userId: action.payload.data.userId,
      };
      break;
    }
    case Constants.LOGIN_PENDING: {
      localStorage.removeItem('token');
      state = {
        ...state,
        isLoading: true,
        authError: '',
        userId: null,
      };
      break;
    }
    case Constants.LOGIN_FULFILLED: {
      localStorage.setItem('token', action.payload.data.token);
      state = {
        ...state,
        isLoading: false,
        token: action.payload.data.token,
        userId: action.payload.data.id,
        authError: '',
      };
      break;
    }
    case Constants.LOGIN_REJECTED: {
      state = {
        ...state,
        isLoading: false,
        token: null,
        authError: action.payload.response.data.message,
      };
      break;
    }
    case Constants.LOGOUT: {
      localStorage.removeItem('token');
      state = {
        ...state,
        token: null,
        userId: null,
      };
      break;
    }
    case Constants.CLEAR_ERROR: {
      state = {
        ...state,
        authError: '',
      };
      break;
    }
    default:
  }
  return state;
};
