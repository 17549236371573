import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Input from '../components/UI/Input';
import Button from '../components/UI/Button';
import CodeReader from '../components/CodeReader';

import { loginUser } from '../store/actions/authActions';

import classes from './Auth.module.css';

export const Auth = () => {
  const [login, setlogin] = useState('');
  const [password, setpassword] = useState('');
  const [logintype, setlogintype] = useState('scan');
  const dispatch = useDispatch();
  const authError = useSelector(state => state.auth.authError);
  const isLoading = useSelector(state => state.auth.isLoading);

  let loginForm = null;
  if (isLoading) {
    loginForm = <div>Авторизация</div>;
  } else {
    if (logintype === 'login') {
      loginForm = (
        <>
          <Button
            btnType="authSwitchButton"
            onClick={() => {
              setlogintype('scan');
            }}
          >
            Скан кода
          </Button>
          <div className={classes.inputs}>
            <Input
              label="имя пользователя"
              value={login}
              name="login"
              onChange={e => setlogin(e.currentTarget.value)}
              placeholder="имя пользователя"
            />
            <Input
              label="пароль"
              type="password"
              value={password}
              name="psw"
              onChange={e => setpassword(e.currentTarget.value)}
              placeholder="пароль"
            />
          </div>
          {authError ? (
            <div className={classes.error}>{authError}</div>
          ) : null}
          <Button
            btnType="authButton"
            onClick={() => {
              dispatch(loginUser(logintype, { login, password }));
            }}
          >
            Войти
          </Button>
        </>
      );
    } else {
      const scanHandler = data => {
        dispatch(loginUser(logintype, data));
      };
      loginForm = (
        <>
          <Button
            btnType="authSwitchButton"
            onClick={() => {
              setlogintype('login');
            }}
          >
            Ввести логин
          </Button>
          <div className={classes.reader}>
            <CodeReader addCode={scanHandler} />
          </div>
          {/* <Button btnType="authButton" onClick={() => {}}>
          Сканировать
        </Button> */}
        </>
      );
    }
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.authForm}>{loginForm}</div>
      </div>
      <div className={classes.backdrop}></div>
    </>
  );
};

export default Auth;
