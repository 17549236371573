import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../UI/Input';
import classes from './CodeReader.module.css';

const CodeReader = ({ addCode }) => {
  const [code, setcode] = useState('');

  return (
    <div className={classes.wrapper}>
      <div className={classes.input}>
        <Input
          type="text"
          name="codeEnter"
          label="Считайте/введите код"
          value={code}
          autoFocus
          onKeyPress={e => {
            if (e.key === 'Enter') {
              addCode(code.trim());
              setcode('');
            }
          }}
          onChange={e => {
            setcode(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

CodeReader.propTypes = {
  addCode: PropTypes.func,
};

CodeReader.defaultProps = {
  addCode: () => {},
};

export default CodeReader;
