import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Unpack from '../../components/Unpack';
import { changeTab } from '../../store/actions/menuActions';
import {
  createUnpack,
  unpack,
} from '../../store/actions/dataActions';
import { logoutUser } from '../../store/actions/authActions';
import classes from './MainMenu.module.css';

export const MainMenu = () => {
  // const [showUnpack, setshowUnpack] = useState(false);
  const activeTab = useSelector(store => store.menu.activeTab);
  const showUnpack = useSelector(store => store.data.showUnpack);
  const dispatch = useDispatch();

  return (
    <div className={classes.wrapper}>
      {showUnpack ? (
        <Unpack
          save={data => {
            dispatch(createUnpack(data));
          }}
          close={() => dispatch(unpack(false))}
        />
      ) : null}
      <div
        className={
          activeTab === 'income'
            ? classes.menuButtonActive
            : classes.menuButton
        }
        onClick={() => {
          if (activeTab === 'income') {
          } else {
            dispatch(changeTab('income'));
          }
        }}
      >
        Приходы
      </div>
      <div
        className={
          activeTab === 'outcome'
            ? classes.menuButtonActive
            : classes.menuButton
        }
        onClick={() => {
          if (activeTab === 'outcome') {
          } else {
            dispatch(changeTab('outcome'));
          }
        }}
      >
        Расходы
      </div>
      <div
        className={classes.unpackButton}
        onClick={() => dispatch(unpack(true))}
      >
        Распаковка
      </div>
      {/* <div
        className={classes.exitButton}
        // active={activeTab === 'exit'}
        onClick={() => dispatch(logoutUser())}
      >
        Выход
      </div> */}
    </div>
  );
};

export default MainMenu;
