import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Button from '../UI/Button';
import CodeReader from '../CodeReader';
import { chooseParty } from '../../store/actions/dataActions';
import classes from './Document.module.css';

export const Document = ({ updateDoc, closeDoc }) => {
  const [page, setpage] = useState('goods');
  const [error, seterror] = useState('');
  const [codes, setcodes] = useState([]);
  const [goods, setgoods] = useState([]);
  const currentDocument = useSelector(store => {
    return store.data.currentDocument;
  });
  const currentParty = useSelector(store => {
    return store.data.currentParty;
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const newGoods = [];
    const newCodes = [];
    currentDocument.tovars.forEach(el => {
      const foundGood = newGoods.filter(
        good => good.uid === el.uid && good.uidParty === el.uidParty
      )[0];
      if (!foundGood) {
        newGoods.push({ ...el });
      } else {
        foundGood.quantity += el.quantity;
      }
      if (el.datamatrix) {
        newCodes.push({
          name: el.name,
          uid: el.uid,
          party: el.party,
          uidParty: el.uidParty,
          checked: false,
          datamatrix: el.datamatrix,
        });
      }
    });
    setgoods(newGoods);
    setcodes(newCodes);
    return () => {};
  }, []);

  const scanHandler = code => {
    if (code.length !== 20 && code.length < 35) {
      // if (code.length !== 20 && code[code.length - 1] !== '=') {
      seterror(
        <div>
          <div>Cчитан неверный код!!!</div>
          <div>{code}</div>
        </div>
      );
      return;
    }
    const foundCode = codes.filter(el => el.datamatrix === code);

    if (
      ((currentDocument.docType === 'income' &&
        currentDocument.workflow) ||
        (currentDocument.docType === 'outcome' &&
          !currentDocument.workflow)) &&
      foundCode.length === 1
    ) {
      seterror(
        <div>
          <div>Этот код уже считан!</div>
          <div>{foundCode[0].name}</div>
          <div>{foundCode[0].party}</div>
        </div>
      );
      // setcodes([
      //   ...codes,
      //   {
      //     name: currentParty.name,
      //     uid: currentParty.uid,
      //     party: currentParty.party,
      //     uidParty: currentParty.uidParty,
      //     checked: true,
      //     datamatrix: code,
      //   },
      // ]);
    } else if (foundCode.length === 0) {
      setcodes([
        ...codes,
        {
          name: currentParty.name,
          uid: currentParty.uid,
          party: currentParty.party,
          uidParty: currentParty.uidParty,
          checked: false,
          datamatrix: code,
        },
      ]);
    } else {
      //should be error notification
      if (foundCode.length === 0) {
        seterror('Такого кода нет в документе!');
      } else {
        seterror(
          <div>
            <div>Этот код уже считан!</div>
            <div>{foundCode[0].name}</div>
            <div>{foundCode[0].party}</div>
          </div>
        );
      }
    }
  };

  let dataView = null;

  if (error !== '') {
    dataView = (
      <div className={classes.error}>
        <div className={classes.errorText}>{error}</div>
        <Button btnType="errorButton" onClick={() => seterror('')}>
          Продолжить
        </Button>
      </div>
    );
  } else if (!currentParty || page === 'goods') {
    dataView = (
      <div className={classes.tovars}>
        {goods.map(el => {
          const scanned = codes.filter(
            code =>
              code.uid === el.uid && code.uidParty === el.uidParty
          ).length;

          return (
            <div
              className={classes.tovar}
              key={`${el.uid}${el.uidParty}`}
              onClick={() => {
                setpage('scan');
                dispatch(chooseParty(el.uid, el.uidParty));
              }}
            >
              <div className={classes.tovarName}>{el.name}</div>
              <div className={classes.tovarParty}>{el.party}</div>
              <div className={classes.tovarQuantity}>
                {`Количество: ${el.quantity || 0}`}
              </div>
              <div
                className={
                  scanned === el.quantity
                    ? classes.tovarScanedEqual
                    : classes.tovarScaned
                }
              >
                {scanned}
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (page === 'scan') {
    dataView = (
      <div className={classes.reader}>
        <CodeReader addCode={scanHandler} />
      </div>
    );
  } else {
    const datamatrixView = code => {
      if (code.length === 20) {
        return code;
      }
      if (`${code[0]}${code[1]}` === '01') {
        return `${code.slice(2, 16)}  ${code.slice(18, 31)}`;
      }
      return `${code.slice(3, 17)}  ${code.slice(19, 32)}`;
    };
    dataView = (
      <div className={classes.codes}>
        {codes.map(el => {
          return (
            <div className={classes.code} key={el.datamatrix}>
              {datamatrixView(el.datamatrix)}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.buttons}>
        <Button
          btnType="editButton"
          onClick={() => updateDoc(currentDocument.id, codes)}
        >
          Занести данные
        </Button>
        <div></div>
        <Button btnType="closeButton" onClick={closeDoc}>
          X
        </Button>
      </div>
      {dataView}
      <div className={classes.buttons}>
        <Button
          btnType="authSwitchButton"
          onClick={() => setpage(page !== 'goods' ? 'goods' : 'scan')}
          disabled={!currentParty}
        >
          {page !== 'goods' ? 'Товары' : 'Сканер'}
        </Button>
        <Button
          btnType="authSwitchButton"
          onClick={() => setpage(page !== 'codes' ? 'codes' : 'scan')}
          disabled={!currentParty}
        >
          {page !== 'codes' ? 'Считанные коды' : 'Сканер'}
        </Button>
      </div>
    </div>
  );
};

Document.propTypes = {
  closeDoc: PropTypes.func.isRequired,
  updateDoc: PropTypes.func.isRequired,
};

export default Document;
